var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"cZ8fS8563LHtn9rsM-YX7"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "/resources/arm64/20250411-1546-c2ab1509bb_website";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

import { Config } from './config';

const isAllowedStage = () => Config.stage === 'alpha' || Config.stage === 'beta' || Config.stage === 'production';

const getInitialSentryOptions = () => {
  if (isAllowedStage()) {
    return {
      sampleRate: 1,
      tracesSampleRate: 0.0001,
      replaysOnErrorSampleRate: 0.00001,
      replaysSessionSampleRate: 0,
    };
  }

  return {
    sampleRate: 0,
    tracesSampleRate: 0,
    replaysOnErrorSampleRate: 0,
    replaysSessionSampleRate: 0,
  };
};

/**
 * 인앱 브라우저에서 발생하는 에러의 변수명들
 * 네이버 - collapseBalloonLayer
 * 인스티즈 - shakehot, memcovercolor, memno, gohot
 * 인스타그램 - _AutofillCallbackHandler
 * 카카오 - isWifi
 */
const external_in_app_browser_variable_errors = [
  '_AutofillCallbackHandler',
  'collapseBalloonLayer',
  'shakehot',
  'memcovercolor',
  'gohot',
  'memno',
  'isWifi',
  'gmo', // iOS 구글앱 내에서 발생하는 이슈로, 센트리 개발자에 의해 ignoreErrors 처리가 권장됨 https://github.com/getsentry/sentry-javascript/issues/15389#issuecomment-2654198884
];

function isExternalInAppVariableError(error_message) {
  const match = error_message.match(/Can't find variable: (\w+)/i);
  return match && match[1] ? external_in_app_browser_variable_errors.includes(match[1]) : false;
}

function isExternalReferer(referer) {
  return referer ? !referer.includes('zigzag.kr') : false;
}

// MEMO: https://sentry.io/answers/handle-hard-navigation-errors-in-nextjs/
function isNextNavigationError(error_message) {
  return error_message.includes('Invariant: attempted to hard navigate to the same URL');
}
// 틱톡 인앱 브라우저의 "Cannot read properties of undefined (reading 'domInteractive')" 에러를 무시합니다.
// https://zigzag-production.sentry.io/issues/5048137279/events/6dc58db6600f4de4a0e00b9ad7965e9a/
function isTiktokInAppBrowserError(event) {
  try {
    const userAgent = event.request?.headers?.['User-Agent']?.toLowerCase() || '';
    const errorMessage = event.exception?.values?.[0]?.value || '';

    if (userAgent.includes('trill') && errorMessage.includes('domInteractive')) {
      return true;
    }
  } catch (error) {
    return false;
  }
}

const EXTRA_KEY = 'ROUTE_TO';

// MEMO: https://docs.sentry.io/platforms/javascript/best-practices/micro-frontends/
const transport = Sentry.makeMultiplexedTransport(Sentry.makeFetchTransport, (args) => {
  const event = args.getEvent();
  if (event && event.extra && EXTRA_KEY in event.extra && Array.isArray(event.extra[EXTRA_KEY])) {
    return event.extra[EXTRA_KEY];
  }
  return [];
});

Sentry.init({
  ...getInitialSentryOptions(),
  dsn: Config.sentry_dsn || 'https://00ef6e4ca50e4f2aad0eebbf2d69d10c@o314573.ingest.sentry.io/6036870',
  environment: Config.stage || process.env.NODE_ENV,
  release: Config.release,
  transport,
  beforeSend: (event, hint) => {
    const referer = (typeof window !== 'undefined' && document.referrer) || event.request?.headers?.referer;
    if (isExternalReferer(referer)) {
      return null;
    }

    const error_message = event.exception?.values?.[0].value;

    if (isExternalInAppVariableError(error_message)) {
      return null;
    }

    if (isNextNavigationError(error_message)) {
      return null;
    }

    if (isTiktokInAppBrowserError(event)) {
      return null;
    }

    // MEMO: https://docs.sentry.io/platforms/javascript/best-practices/micro-frontends/
    if (event?.exception?.values?.[0]?.stacktrace?.frames) {
      const frames = event.exception.values[0].stacktrace.frames;
      // Find the last frame with module metadata containing a DSN
      const routeTo = frames
        .filter((frame) => frame.module_metadata && frame.module_metadata.dsn)
        .map((v) => v.module_metadata)
        .slice(-1); // using top frame only - you may want to customize this according to your needs

      if (routeTo.length) {
        event.extra = {
          ...event.extra,
          [EXTRA_KEY]: routeTo,
        };
      }
    }

    try {
      if (
        typeof hint.originalException === 'object' &&
        hint.originalException != null &&
        'message' in hint.originalException &&
        event.exception.values.length > 0
      ) {
        event.exception.values[0].type = hint.originalException.name || 'Error';
        event.exception.values[0].value = hint.originalException.message;

        if (!(hint.originalException instanceof Error)) {
          delete event.exception.values[0].mechanism.synthetic;
        }
      }
    } catch (e) {
      // do nothing
    }

    return event;
  },
  integrations: [
    Sentry.moduleMetadataIntegration(),
    new Sentry.Replay({
      maskAllText: true,
    }),
  ],
  ignoreErrors: [
    'Error: Failed to fetch',
    'TypeError: Failed to fetch',
    'Error: Load failed',
    'TypeError: Load failed',
    'Error: NetworkError when attempting to fetch resource.',
    'TypeError: NetworkError when attempting to fetch resource.',
    'Error: cancelled',
    'TypeError: cancelled',
    'Error: 취소됨',
    'TypeError: 취소됨',
    'ReferenceError: NetFunnel is not defined',
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded', // 파이어베이스에서 중복 집계되는 중, 일단 ignore 처리 후 추후 PDP 상품정보 lazy 개선 후 추이 지켜보기
    'zaloJSV2', // 베트남 메신저 관련 외부 에러
    '__naverapp__', // 네이버 번역기 관련 외부 에러
    'OcbiOSJS', // OK 캐시백 관련 외부 에러
    'InvalidStateError: The object is in an invalid state.', // PDP 동영상 재생 시도시 브라우저 정책으로 나는 경고 무시 (화면 크래시 없음)
  ],
  denyUrls: [/extensions\//i, /^chrome:\/\//i],
});
