import { useQuery } from 'react-query';

import { getUserPurchaseAndInstallStatus } from '../graphql/is_first_order_user.2';
import { useGetUserNameQuery } from './useGetUserName';
import { useIsToss } from '@common/device-manager';

const QUERY_KEY = 'getUserPurchaseAndInstallStatus';

export const useGetUserPurchaseAndInstallStatus = () => {
  const { data: user, isLoading: is_user_loading } = useGetUserNameQuery();
  const uuid = user?.user_account?.uuid ?? '';
  const { data, isLoading: is_status_loading } = useQuery(
    QUERY_KEY,
    async () => {
      const { data } = await getUserPurchaseAndInstallStatus(null, {
        show_alert: false,
      });
      return {
        is_first_order_user: data?.CmsIsFirstOrderUser,
        is_app_installed: data?.CmsIsAppInstalledUser,
        kakao_connected: data?.user_account?.kakao_connected,
      };
    },
    {
      enabled: !!uuid,
    },
  );
  const is_loading = is_user_loading || is_status_loading;

  return {
    isLogedIn: !!uuid,
    is_first_order_user: Boolean(data?.is_first_order_user),
    is_app_installed: Boolean(data?.is_app_installed),
    kakao_connected: Boolean(data?.kakao_connected),
    is_loading,
    uuid,
  };
};
