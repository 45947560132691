import React, { useEffect, useState } from 'react';
import { css } from '@emotion/react';
import getConfig from 'next/config';

import { useUserLocale } from '@common/i18n';
import { useAppBannerVisibility } from '@widgets/web-landing-banner';

const runtime_config = getConfig().publicRuntimeConfig;

const origin_url = typeof window !== 'undefined' ? window.location.origin : '';

export const env = runtime_config?.stage
  ? runtime_config.stage
  : origin_url.includes('zigzag.kr')
    ? 'production'
    : (origin_url.match(/(.*)\.zigzag/)?.[1] ?? 'local');

const Stage = () => {
  const [stage, setStage] = useState<string>('');
  // const [mounted, setMounted] = useState(false);
  const { language, country, currency } = useUserLocale();
  // MEMO: 선 배포 후 테스트를 위해 사용될 예정
  // const {
  //   isLogedIn,
  //   is_app_installed,
  //   is_first_order_user,
  //   kakao_connected,
  //   isNaverEp,
  //   isKakaotalk,
  //   isToss,
  //   is_kakao_banner_hidden,
  //   is_app_install_banner_hidden,
  // } = useAppBannerVisibility();

  useEffect(() => {
    setStage(env);
  }, []);

  // useEffect(() => {
  //   setMounted(true);
  // }, []);

  // if (!mounted) return null;

  return (
    <div
      css={css`
        position: fixed;
        bottom: 80px;
        left: 0;
        z-index: 10000;
        pointer-events: none;
        display: ${!!stage && stage !== 'production' ? 'block' : 'none'};
        font-size: 13px;
        opacity: 0.5;
        color: green;

        .stage {
          margin: 0 3px;
          font-weight: bold;
        }

        .user-locale {
          font-size: 10px;
        }
      `}
    >
      <span className='stage'>{stage.toUpperCase()}</span>
      <span className='user-locale'>
        language: {language}, country: {country}, currency: {currency}
      </span>
      {/* <div
        css={css`
          > span {
            display: block;
          }
        `}
      >
        <span>로그인: {String(isLogedIn)}</span>
        <span>앱설치: {String(is_app_installed)}</span>
        <span>첫구매: {String(is_first_order_user)}</span>
        <span>카카오 연동: {String(kakao_connected)}</span>
        <span>네이버 EP: {String(isNaverEp)}</span>
        <span>카카오 브라우저: {String(isKakaotalk)}</span>
        <span>토스: {String(isToss)}</span>
        <span>앱설치 배너 hidden: {String(is_app_install_banner_hidden)}</span>
        <span>카카오 오픈 채널 배너 hidden: {String(is_kakao_banner_hidden)}</span>
      </div> */}
    </div>
  );
};
export default Stage;
