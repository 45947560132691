import { useEffect, useMemo, useState } from 'react';
import { Cookies } from 'react-cookie';
import { isBrowser } from '@croquiscom-pvt/web2app';

import { isKakaotalk, useIsToss } from '@common/device-manager';

import { useGetUserPurchaseAndInstallStatus } from '../queries/useGetUserPurchaseAndInstallStatus';

export const useAppBannerVisibility = () => {
  const isToss = useIsToss();
  const cookies = new Cookies();
  const { isLogedIn, is_first_order_user, kakao_connected, is_app_installed, is_loading, uuid } =
    useGetUserPurchaseAndInstallStatus();
  const [utmSource, setUtmSource] = useState(cookies.get('utm_source') ?? '');
  const isNaverEp = utmSource?.includes('shopping_naver_all') || false;
  const [showAppInstallBanner, setShowAppInstallBanner] = useState(true);

  /**
   * 앱 설치 배너 미노출 조건
   * 1) 카카오톡 브라우저 (isKakaotalk())
   * 2) 네이버 EP (isNaverEp)
   * 3) 토스 브라우저인 경우
   *    - 첫 주문 유저 (is_first_order_user)
   *    - 이미 앱이 설치되어 있음 (is_app_installed)
   *    - showAppInstallBanner 값이 false인 경우
   */
  const is_app_install_banner_hidden = useMemo(() => {
    if (is_loading) return true;

    if (!isBrowser()) return true;

    if (isKakaotalk()) return true;

    if (isNaverEp) return true;

    if (is_first_order_user || is_app_installed || !showAppInstallBanner) return true;

    if (!isLogedIn) return true;

    return false;
  }, [is_loading, isNaverEp, is_first_order_user, is_app_installed, showAppInstallBanner, isLogedIn]);

  /**
   * 카카오 오픈 채널 랜딩 배너 미노출 조건
   * 1) 네이버 EP 환경 (isNaverEp)
   * 2) 카카오톡 브라우저 (isKakaotalk())
   * 3) 첫 구매 유저인 경우
   * 4) 로그인 여부별:
   *    - 로그인 X → 무조건 미노출
   *    - 로그인 O & 카카오 계정 연결(kakao_connected) → 미노출
   */
  const is_kakao_banner_hidden = useMemo(() => {
    if (is_loading) return true;

    if (!isBrowser()) return true;

    // 1) 네이버 EP
    if (isNaverEp) return true;

    // 2) 카카오톡 브라우저
    if (isKakaotalk()) return true;

    // 3) 토스 브라우저 & 첫 구매인 유저인 경우
    if (is_first_order_user) return true;

    // 4) 로그인 여부
    if (!isLogedIn) {
      // 미로그인 → 무조건 미노출
      return true;
    } else {
      // 로그인 상태 → 카카오 계정 연동됐으면 미노출
      return kakao_connected;
    }
  }, [is_loading, isNaverEp, is_first_order_user, is_app_installed, isLogedIn]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlUtmSource = params.get('utm_source');
    const bannerParam = params.get('showAppInstallBanner');

    // URL 쿼리에 utm_source가 있으면 state에 세팅 (없으면 기존 쿠키값 유지)
    if (urlUtmSource) {
      setUtmSource(urlUtmSource);
    }

    setShowAppInstallBanner(bannerParam !== 'false'); // 토스측에서 hide 처리할 때 showAppInstallBanner=false로 보낼 수 있음
  }, []);

  useEffect(() => {
    try {
      if (isToss && uuid) {
        localStorage.setItem('uuid', uuid);
      }
    } catch (err) {
      console.error(err);
    }
  }, [isToss, uuid]);

  return {
    is_app_install_banner_hidden,
    is_kakao_banner_hidden,
    isLogedIn,
    is_first_order_user,
    is_app_installed,
    kakao_connected,
    isNaverEp,
    isKakaotalk: isKakaotalk(),
    isToss,
  };
};
