import { forwardRef, ReactNode } from 'react';
import { ProductCard, ProductCardProps } from '@croquiscom-pvt/zds';
import { css } from '@emotion/react';
import clsx from 'clsx';

export interface ProductCardRootProps extends Pick<ProductCardProps, 'size' | 'soldOut'> {
  children: ReactNode;
  className?: string;
}

const productCardCss = css`
  position: relative;
  display: flex;
`;

export const ProductCardRoot = forwardRef<HTMLDivElement, ProductCardRootProps>((props, ref) => {
  const { size, soldOut, children, className } = props;

  return (
    <ProductCard
      css={productCardCss}
      className={clsx(['product-card-root', className])}
      ref={ref}
      size={size}
      soldOut={soldOut}
    >
      {children}
    </ProductCard>
  );
});
